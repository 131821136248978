<template>
    <div style="background-color: #f8f9fb; min-height: 100%">
        <div class="header">
            <div class="top top_mafi f-left">
                <div class="util">
                    <button class="arrow_white" @click="goBack">
                        <i class="icon icon-arrow-left"></i>
                    </button>
                </div>
                <h2 class="page_tit">출석체크</h2>
            </div>
        </div>

        <div class="section_wrap pt-50">
            <div class="container">
                <div class="row">
                    <div class="pt-35">
                        <h2 class="attendance_title text-center">
                            메타마피아<br />
                            <strong>출석체크</strong>
                        </h2>
                    </div>

                    <!-- 출석체크 달력 -->
                    <div class="position-relative mt-30" style="z-index: 1">
                        <div class="attendance_bg_top"></div>
                        <div class="white_box">
                            <div class="calendar_title">
                                <h3>{{ month }}월</h3>
                                <div>
                                    <span class="attendance_date">{{  items.length }}일</span>
                                    <span>출석</span>
                                </div>
                            </div>

                            <!-- 달력 -->
							<div class="position-relative">
								<div class="position-absolute-full" style="z-index: 99"></div>
								<v-date-picker

									no-title
									width="100%"
									locale="ko"
									:day-format="krGetDay"
									:allowed-dates="disabledPastDates"
									:events="date"
								></v-date-picker>

							</div>

                            <div class="pt-20">
                                <button class="btn_center btn_l " :class="is_check ? 'btn_fill_lightgray' : 'btn_fill_blue'" style="width: 208px" @click="sample" >오늘 출석하기</button>
                            </div>
                        </div>
                    </div>

                    <!-- 출석체크 알림 -->
                    <div class="white_box mt-10">
                        <div class="attendance_alert">
                            <div class="flex align_items_center gap-1">
                                <img :src="require('@/assets/image/ico_attendance_alarm.svg')" alt="" />
                                <div class="flex-column">
                                    <h4 class="size-px-18 fw-900">출석체크 알림</h4>
                                    <p class="color-333 mt-5">오후 1시 알림이 전달됩니다.</p>
                                </div>
                            </div>
                            <div class="toggle-group">
                                <!-- TODO : 수신 허용시 .on 클래스 추가 -->
                                <span class="btn-toggle" :class="{ on: item.is_on_alram }" @click="item.is_on_alram = !item.is_on_alram"><em class="hide">출석체크 알림</em></span>
                            </div>
                        </div>
                    </div>

                    <!-- 출석체크 유의사항 -->
                    <div class="white_box mt-10">
                        <div class="attendance_notice">
                            <h5 class="color-333">출석체크 유의사항</h5>
                            <ul class="list_dot mt-15">
                                <li class="color-333">본 출석체크는 1일 1회 앱 접속 후 참여 가능합니다.</li>
                                <li class="color-333">출석과 동시에 일정 MAF 포인트를 회원님에게 지급되고 있습니다.</li>
                                <li class="color-333">지급된 MAF 포인트는 타인에게 양도/판매가 불가합니다.</li>
                                <li class="color-333">지급된 MAF 포인트는 MAF 코인으로 전환이 가능합니다.</li>
                                <li class="color-333">(주)메타쿱의 사정으로 출석체크 보상 변경 및 중단될 수 있습니다.</li>
                                <li class="color-333">
                                    매일 00:00~23:59 출석 시에만 당일 출석으로 인정되며 마감시간 전 시스템 오류 및 사용자 네트워크 성능에 의해 출석 체크가 불가능할 수 있습니다.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Attendance',
    compontents: {},
    props: ['user'],
    data: function () {
        return {
            name: 'attendance',
            program: {
                name: '',
                title: '',
                type: 'mypage',
                not_footer: true,
            },
            item: {
                is_on_alram: true,
            },
			items: []
			, date: []
			, is_check: false
        };
    },
    computed: {
		month: function(){
			let t = ''
			t = Number(this.$date.init(this.$date.getToday('-'), '-').month)
			return t
		}
		, year: function(){
			let t = ''
			t = Number(this.$date.init(this.$date.getToday('-'), '-').year)
			return t
		}
	},
    methods: {
        goBack: function () {
            this.$bus.$emit('goBack');
        },
        /* locale 한국어 날짜 '일' 텍스트 제거 */
        krGetDay(date) {
            const day = date.split('-')[2];
            const day_num = Number(day);
            return day_num;
        },
        /* 오늘 이전 disabled 처리 */
        disabledPastDates(e) {
            return e >= new Date().toISOString().substr(0, 10);
        },
        /* 버튼 클릭 시 클래스 추가 */
        sample() {
            this.postCheck()
        },

		async getData(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_check
					, data: {
						member_number: this.user.member_number
						, year: this.year
						, month: this.month
					}
					, type: true
				})
				if(result.success){
					this.items = result.data.presence_day_list
					this.items.filter( (item) => {
						this.date.push(item.presence_check_year_mm_day)
						if(item.presence_check_year_mm_day == this.$date.getToday('-')){
							this.is_check = true
						}
					})

					setTimeout(() => {

						let current = document.getElementsByClassName('v-date-picker-table__events');
						console.log(current)
						for(let i = 0; i < current.length; i++){
							let item = current[i]
							item.parentElement.classList.add('attendance_on');
						}
					}, 100)

				}else{
					throw result.message
				}
			}catch (e){
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})

			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, async postCheck(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_check
					, data: {
						member_number: this.user.member_number
					}
					, type: true
				})
				if(result.success){
					console.log(result.data)

					this.$bus.$emit('notify', { type: 'success', message: result.data.presence_check_reg_result_message})
					if(result.data.presence_check_reg_success_fg == 'Y'){
						document.location.reload()
					}
				}else{
					throw result.message
				}
			}catch (e){
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})

			}finally {
				this.$bus.$emit('on', false)
			}
		}
    },
    created() {
        this.$bus.$emit('onLoad', this.program);
		this.getData()
    },
};
</script>

<style scoped>
button:disabled {
    color: var(--white);
}
/* 달력 커스텀 */
.v-picker >>> .v-date-picker-header {
    display: none;
}
.v-picker >>> .v-date-picker-table {
    height: unset;
    padding: 0;
}
.v-picker >>> .v-date-picker-table table {
    border-collapse: unset;
    border-spacing: 0px 10px;
}
.v-picker >>> .v-date-picker-table th {
    font-size: 14px;
    font-weight: 400;
    color: #000;
}

.v-picker >>> .v-date-picker-table td button {
    color: var(--dark-Gray02);
    font-weight: 500;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 100px;
}
.v-picker >>> .v-date-picker-table td button:disabled {
    background: transparent;
    color: #bcbcbc;
}
.v-picker >>> .v-date-picker-table td button:focus {
    background: transparent !important;
}
.v-picker >>> .theme--light.v-btn:focus::before,
.v-picker >>> .theme--light.v-btn:hover::before {
    background: transparent !important;
}
.v-picker >>> .v-date-picker-table th:nth-child(1),
.v-picker >>> .v-date-picker-table td:nth-child(1) button {
    color: #ff0000;
}
.v-picker >>> .v-date-picker-table--date .v-btn {
    width: 30px;
    height: 30px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    text-indent: unset;
    letter-spacing: unset;
}
.v-picker >>> .v-btn--outlined {
    border: 1px solid var(--blue02);
    color: var(--blue02) !important;
    background: transparent !important;
}

.v-picker >>> .v-btn--outlined .v-btn__content {
    color: var(--blue02) !important;
}

.v-picker >>> .v-btn.attendance_on {
    background: url('../../assets/image/ico_attendance_done.svg') no-repeat center center / 30px 30px !important;
    font-size: 0 !important;
    border: 0 !important;
}

</style>
<style>

.v-date-picker-table__events.attendance_on  {
	background: url('../../assets/image/ico_attendance_done.svg') no-repeat center center / 30px 30px !important;
}
</style>
